.fab {
  color: #9c3353;
}
h6 {
  color: #9c3353;
}
.aboutUs {
  color: black;
  font-weight: 600;
}
.aboutUs:hover {
  color: black;
}
