.module {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-page {
  border: 1px solid rgb(21, 20, 20);
  height: 80vh;
}
main-image {
  width: 50%;
}
