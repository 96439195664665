.homerow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}
.elcard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f2;
  height: 550px;
}
.heroimg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-image {
  width: 70%;
}

.eligible1-image {
  width: 150px;
}

.eligible2-image {
  width: 185px;
  margin-top: -15%;
}

.home-container {
  height: 100vh;
}
.section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  border: 0;
  position: relative;
  overflow: hidden;
  border-radius: 10rem;
  transition: all 0.02s;
  cursor: pointer;
  z-index: 0;
  color: #000000;
  font-weight: 900;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.7);
  background: rgba(255, 255, 255, 0.9);
  letter-spacing: 0.5px;
  box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.3);
}

.button:hover {
  color: #000000;
  background: rgba(176, 255, 75, 0.2);
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 15px rgba(176, 255, 75, 0.3);
}

.button:active {
  transform: scale(0.97);
}

.hoverEffect {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.hoverEffect div {
  background: rgb(255, 234, 0);
  background: linear-gradient(
    90deg,
    rgba(255, 234, 0, 1) 0%,
    rgba(176, 255, 75, 1) 49%,
    rgba(0, 255, 128, 1) 100%
  );
  border-radius: 40rem;
  width: 10rem;
  height: 10rem;
  transition: 0.4s;
  filter: blur(20px);
  animation: effect infinite 3s linear;
  opacity: 0.5;
}

.button:hover .hoverEffect div {
  width: 8rem;
  height: 8rem;
}

@keyframes effect {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.animate-float {
  animation: float 6s ease-in-out infinite;
}
