.msg-sent {
  background-color: #b0ff4b;
  padding: 20px;
  width: fit-content;
  margin-left: auto;
  border-radius: 20px 20px 5px 20px;
  border-bottom: 3px solid #5f1f32;
  border-left: 2px solid #5f1f32;
  color: white;
  font-weight: bold;
  margin-bottom: 20px;
  max-width: 70%;
}

.msg-rec {
  background-color: white;
  padding: 20px;
  width: fit-content;
  border-radius: 20px 20px 20px 5px;
  border-bottom: 3px solid #c3c1c1;
  border-left: 2px solid #c3c1c1;
  font-weight: bold;
  margin-bottom: 20px;
  max-width: 70%;
}

.chat-body {
  overflow: auto;
}
