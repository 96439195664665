.homerow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}
.elcard {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6f5f2;
  height: 550px;
}
.heroimg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.eligible1-image {
  width: 150px;
}

.eligible2-image {
  width: 185px;
  margin-top: -15%;
}

.home-container {
  height: 100vh;
}
.section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
